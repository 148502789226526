interface Message {
  method: string;
  params?: any;
}

export type MessageCallback = (params?: any) => void;

export abstract class HostPageMessenger {
  public static send(method: string, params?: any) {
    if (window.parentIFrame) {
      window.parentIFrame.sendMessage({method, params});
    }
  }

  public static receive(message: Message) {
    if (message.method === 'ready') {
      this.ready = true;
    }
    (this.listeners[message.method] || []).forEach((callback) => {
      try {
        callback(message.params);
      } catch (e) {
        console.error(e);
      }
    });
  }

  public static on(method: string, callback: MessageCallback) {
    if (this.listeners[method]) {
      if (!this.listeners[method].includes(callback)) {
        this.listeners[method].push(callback);
      }
    } else {
      this.listeners[method] = [callback];
    }
    if (method === 'ready' && this.ready) {
      setTimeout(() => callback(), 0);
    }
  }

  public static off(method: string, callback: MessageCallback) {
    if (this.listeners[method]) {
      const index = this.listeners[method].indexOf(callback);
      if (index >= 0) {
        this.listeners[method].splice(index, 1);
      }
    }
  }

  private static ready: boolean = false;
  private static listeners: {[method: string]: MessageCallback[]} = {};
}
