import {Schema} from '@zaiusinc/app-forms-schema';
import {ZipApi} from './ZipApi';

export async function fetchRemoteData(
  section: string, field: string, source: Schema.DataSource, formData: Schema.FormSectionData, installId: number
): Promise<Schema.SelectOption[]> {
  if (source.type === 'app') {
    const fns = await ZipApi.listFunctions(installId);
    const url = fns[source.function];
    if (!url) {
      return [];
    }
    return await processResponse(await fetch(url, {
      method: 'POST',
      body: JSON.stringify({section, field, formData}),
      headers: {
        'Content-Type': 'application/json'
      }
    }));
  } else {
    if (source.url) {
      return await processResponse(await fetch(source.url, {
        method: 'GET',
        headers: {
          Accept: 'application/json'
        }
      }));
    }
    return [];
  }
}

async function processResponse(response: Response) {
  const result = await response.json();
  if (!Array.isArray(result)) {
    return [];
  }

  return result;
}
