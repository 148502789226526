import {Callout, Icon, Intent, H2} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {SearchInput} from '@zaius/hera';
import chunk from 'lodash/chunk';
import pullAt from 'lodash/pullAt';
import {useObserver} from 'mobx-react-lite';
import React, {useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {HostPageMessenger} from '../lib/HostPageMessenger';
import {inIframe} from '../lib/inIframe';
import {appListStore} from '../stores/AppListStore';
import {directoryStore} from '../stores/DirectoryStore';
import {AppCard, BlankAppCard} from './AppList/AppCard';
import {CategoryFilters} from './AppList/CategoryFilters';
import styles from './AppsListPage.module.sass';

const IFRAME_PARENT_ORIGIN: string = process.env.REACT_APP_IFRAME_PARENT_ORIGIN || (() => {
  const url = new URL(window.location.href);
  const stem = url.host.split('.').slice(1);
  if (stem[stem.length - 2] === 'zaius') {
    url.host = 'app.zaius.com';
    if (stem[0] === 'staging') {
      url.host = 'staging.zaius.com';
    }
    return url.origin;
  }
  stem[1] = 'odp';
  url.host = ['app'].concat(stem).join('.');
  return url.origin;
})();

export const AppsListPage = () => {
  const history = useHistory();
  useEffect(() => {
    if (directoryStore.trackerId) {
      appListStore.fetch();
    }
  }, []);

  return useObserver(() =>
    <div className={styles.container}>
      <div className={styles.header}>
        <H2>App Directory</H2>
        <SearchInput
          searchTerm={appListStore.filter.search}
          onChange={onSearchChange}
          onSearch={() => onSearchEnter(history)}
          placeholder="Search apps"
        />
      </div>
      <div className={styles.body}>
        <div className={styles.sidebar}>
          <CategoryFilters />
        </div>
        {renderAppList()}
      </div>
    </div>
  );
};

function renderAppList() {
  if (!appListStore.loaded || !directoryStore.trackerId) {
    return <LoadingIndicator />;
  }

  if (appListStore.apps.length === 0) {
    return (
      <div className={styles.emptyAppList}>
        <Icon icon={IconNames.SEARCH} iconSize={64} />
        <p>No apps were found</p>
        <p className={styles.info}>
          Other Apps can be found on the <a href="# " onClick={redirectToIntegrations}>Integrations Page</a>
        </p>
      </div>
    );
  }

  return (
    <div className={styles.appList}>
      <Callout intent={Intent.PRIMARY} className={styles.otherAppsCallout}>
        Other apps can be found on the <a href="# " onClick={redirectToIntegrations}>Integrations Page</a>.
      </Callout>
      {chunk(appListStore.apps, 3).map((row, index) => (
        <div key={index} className={styles.appRow}>
          {pullAt(row, [0, 1, 2]).map(
            (app, i) => app ? <AppCard app={app} key={app.id.app_id} /> : <BlankAppCard key={i} />
          )}
        </div>
      ))}
    </div>
  );
}

function onSearchChange(search: string) {
  appListStore.setFilter({search});
}

function onSearchEnter(history: ReturnType<typeof useHistory>) {
  if (appListStore.apps.length === 1) {
    history.push(`/app/${appListStore.apps[0].id.app_id}`);
  }
}

function redirectToIntegrations(e: React.MouseEvent) {
  e.preventDefault();
  if (inIframe()) {
    HostPageMessenger.send('setWindowLocation', {hash: '/integrations'});
  } else {
    window.location.href = `${IFRAME_PARENT_ORIGIN}#/integrations`;
  }
}
