import {Schema} from '@zaiusinc/app-forms-schema';
import {directoryStore} from '../stores/DirectoryStore';

export function filterZaiusOnlySections(data: Schema.Form) {
  const zaiusAccess = directoryStore.userFeatures?.features?.feature_zaius_only_access;
  if (!zaiusAccess) {
    data.sections = data.sections.filter((section) => !section.zaiusAdminOnly);
  }
  // returned as a convenience, data is modifed in place
  return data;
}
