import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {DirectoryWithRouter} from './Directory';
import './index.sass';
import {FullStory} from './lib/FullStory';
import {HostPageMessenger} from './lib/HostPageMessenger';
import {inIframe} from './lib/inIframe';

const IFRAME_PARENT_ORIGIN: string = process.env.REACT_APP_IFRAME_PARENT_ORIGIN || (() => {
  const url = new URL(window.location.href);
  const stem = url.host.split('.').slice(1);
  if (stem[stem.length - 2] === 'zaius') {
    url.host = 'app.zaius.com';
    if (stem[0] === 'staging') {
      url.host = 'staging.zaius.com';
    }
    return url.origin;
  }
  stem[1] = 'odp';
  url.host = ['app'].concat(stem).join('.');
  return url.origin;
})();

HostPageMessenger.on('ready', () => {
  ReactDOM.render((
    <BrowserRouter>
      <DirectoryWithRouter />
    </BrowserRouter>
  ), document.getElementById('root'));
});

if (inIframe()) {
  if (!window.origin.includes('localhost') && window.location.search.includes('fullstory')) {
    FullStory.initialize();
  }

  window.iFrameResizer = {
    targetOrigin: IFRAME_PARENT_ORIGIN!,
    onMessage: (message: any) => HostPageMessenger.receive(message),
    onReady: () => HostPageMessenger.receive({method: 'ready'})
  };
  /* tslint:disable */
  require('iframe-resizer/js/iframeResizer.contentWindow');
  /* tslint:enable */
} else if (window.origin.includes('localhost')) {
  HostPageMessenger.receive({method: 'ready'});
} else {
  window.location.replace('https://zaius.com');
}
