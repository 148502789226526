import {Tab, TabId, Tabs, Tooltip} from '@blueprintjs/core';
import {useHistory, useLocation} from 'react-router-dom';

import {AppDetailStore} from '../../stores/AppDetailStore';
import {AppLogs} from './AppLogs/AppLogs';
import {AppOverview} from './AppOverview';
import {AppSettings} from './AppSettings';
import React from 'react';
import {addToQuery} from '../../lib/history';
import styles from './AppDetailTabs.module.sass';
import {useObserver} from 'mobx-react-lite';

interface Props {
  detailStore: AppDetailStore;
}

interface PanelProps {
  detailStore: AppDetailStore;
  component: React.ComponentClass<Props> | React.SFC<Props>;
}

export function AppDetailTabs({detailStore}: Props) {
  const history = useHistory();
  const query = new URLSearchParams(useLocation().search);
  const selectedTab = query.get('tab') || undefined;
  return useObserver(() => {
    const settingsTitle =
      detailStore.sameVersionInstalled ?
        'Settings' :
        <Tooltip content="Install to configure app settings.">Settings</Tooltip>;
    return (
      <Tabs
        className={styles.container}
        id="app-detail-tabs"
        renderActiveTabPanelOnly
        selectedTabId={selectedTab}
        onChange={(newTabId: TabId) => addToQuery(history, {tab: newTabId.toString()})}
      >
        <Tab id="overview" title="Overview" panel={<TabPanel detailStore={detailStore} component={AppOverview} />} />
        <Tab
          id="settings"
          title={settingsTitle}
          panel={<TabPanel detailStore={detailStore} component={AppSettings} />}
          disabled={!detailStore.sameVersionInstalled}
        />
        { detailStore.hasTroubleshooting && (
          <Tab
            id="logs"
            title="Troubleshoot"
            panel={<TabPanel detailStore={detailStore} component={AppLogs} />}
          />)
        }
      </Tabs>
    );
  });
}

const TabPanel = (props: PanelProps) => (
  <div className={styles.panel}>
    <props.component detailStore={props.detailStore} />
  </div>
);
