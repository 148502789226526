import {useObserver} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import ReactMarkdown from 'react-markdown';
import joinUri from 'url-join';
import {LoadingIndicator} from '../../components/LoadingIndicator';
import {AppDetailStore} from '../../stores/AppDetailStore';
import styles from './AppOverview.module.sass';

interface Props {
  detailStore: AppDetailStore;
}

export const AppOverview = ({detailStore}: Props) => {
  const [overview, setOverview] = useState<string | undefined>(undefined);
  const detail = useObserver(() => detailStore.detail);
  useEffect(() => {
    if (detail) {
      fetch(detail.overview_url)
        .then(async (result) => {
          if (result.ok) {
            setOverview(await result.text() || '');
          } else {
            throw new Error();
          }
        })
        .catch(() => setOverview('There was a problem loading this information.'));
    }
  }, [detail]);

  if (!detail || detailStore.loading) {
    return (
      <LoadingIndicator />
    );
  }

  const transformImageUri = (uri: string) => {
    return /^https?:\/\//.test(uri) ? uri : joinUri(detail.overview_url.replace(/[^/]+.md$/, ''), uri);
  };

  return (
    <ReactMarkdown
      className={styles.markdown}
      source={overview}
      transformImageUri={transformImageUri}
      linkTarget="_blank"
    />
  );
};
