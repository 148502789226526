import equal from 'fast-deep-equal';
import {LocationDescriptorObject} from 'history';
import * as queryString from 'querystring';
import React from 'react';
import {Route, RouteComponentProps, Switch, useParams, withRouter, useLocation} from 'react-router-dom';
import {HostPageMessenger} from './lib/HostPageMessenger';
import {showToasts} from './lib/toaster';
import {AppDetailPage} from './pages/AppDetailPage';
import {AppsListPage} from './pages/AppsListPage';

class Directory extends React.Component<RouteComponentProps, {}> {
  public render() {
    return (
      <Switch>
        <Route path="/app/:appId/:version">
          <AppDetailPageRenderer />
        </Route>
        <Route path="/app/:appId">
          <AppDetailPageRenderer />
        </Route>
        <Route exact path="/">
          <AppsListPage />
        </Route>
        <Route path="/">
          <div>404</div>
        </Route>
      </Switch>
    );
  }

  public componentDidUpdate(prevProps: Readonly<RouteComponentProps>, prevState: Readonly<{}>, snapshot?: any): void {
    const {pathname, search, hash} = this.props.location;
    if (prevProps && prevProps.location && !equal(this.props.location, prevProps.location)) {
      HostPageMessenger.send('navigate', {pathname, search, hash});
    }
    const params = queryString.parse(search.startsWith('?') ? search.substring(1) : search);
    if (params.toasts) {
      showToasts(JSON.parse(atob(params.toasts as string)));
      delete params.toasts;
      this.props.history.replace({...this.props.location, search: queryString.encode(params)});
    }
  }

  public componentDidMount(): void {
    HostPageMessenger.on('navigate', (location: LocationDescriptorObject) => {
      this.props.history.replace(location);
    });
    HostPageMessenger.send('readyToNavigate');
  }
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function AppDetailPageRenderer() {
  const {appId, version} = useParams();
  const standalone = useQuery().has('standalone');
  return <AppDetailPage appId={appId!} version={version} hideBreadcrumbs={standalone} />;
}

export const DirectoryWithRouter = withRouter(Directory);
