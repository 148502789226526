import {Card, Classes, Elevation, Icon, Intent} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {AppListItem} from '../../stores/AppListStore';
import {AppVersion} from '../../types/AppVersion';
import styles from './AppCard.module.sass';
import {directoryStore} from '../../stores/DirectoryStore';

interface Props {
  app: AppListItem;
}

export const AppCard = ({app}: Props) => {
  const {logo_url, display_name, summary, categories} = app.metadata;
  const {app_id} = app.id;

  const history = useHistory();
  const installed = app.installed ? <Icon icon={IconNames.TICK_CIRCLE} intent={Intent.SUCCESS} /> : null;

  return (
    <Card className={styles.card} elevation={Elevation.ONE} interactive onClick={() => history.push(`/app/${app_id}`)}>
      <div className={styles.logoMark}>
        <img src={logo_url} alt={`(${app_id} logo)`}/>
      </div>
      <div className={styles.appName}>{display_name}&nbsp;{installed}</div>
      <div className={styles.appSummary}>{summary}</div>
      <div className={styles.spacer} />
      {directoryStore.requiresOdpUpgrade(app_id) &&
        <div className={styles.upgrade}>
          <Icon icon={IconNames.LOCK} iconSize={12} />&nbsp;Available with ODP upgrade
        </div>
      }
      <div className={styles.appCategories}>
        {renderCategories(categories)}
      </div>
    </Card>
  );
};

function renderCategories(categories: AppVersion['metadata']['categories']) {
  return categories.map((category) => <div className={Classes.TAG} key={category}>{category}</div>);
}

export const BlankAppCard = () => {
  return <Card className={styles.blankCard} />;
};
