import React, {useEffect} from 'react';

import {AppDetailStore} from '../../../stores/AppDetailStore';
import {AppLogContent} from './AppLogContent';
import {AppLogFilters} from './AppLogFilters';
import styles from './AppLogs.module.sass';
import {useObserver} from 'mobx-react-lite';

interface Props {
  detailStore: AppDetailStore;
}

export const AppLogs = ({detailStore}: Props) => {
  useEffect(() => {
    if (detailStore.logFilter.startDate && detailStore.logFilter.endDate) {
      detailStore.fetchLogs();
    }
  });

  return useObserver(() =>
    <div className={styles.container}>
      <AppLogFilters detailStore={detailStore} />
      <AppLogContent detailStore={detailStore} />
    </div>
  );
};
