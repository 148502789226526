import {Icon} from '@blueprintjs/core';
import {IconNames} from '@blueprintjs/icons';
import {when} from 'mobx';
import {observer} from 'mobx-react';
import {useObserver} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';
import {LoadingIndicator} from '../components/LoadingIndicator';
import {AppDetailStore} from '../stores/AppDetailStore';
import {AppDetailTabs} from './AppDetail/AppDetailTabs';
import {AppInfoCard} from './AppDetail/AppInfoCard';
import styles from './AppDetailPage.module.sass';

interface Props {
  appId: string;
  version?: string;
  hideBreadcrumbs?: boolean;
}

@observer
export class AppDetailPage extends React.Component<Props> {
  private detailStore: AppDetailStore;

  public constructor(props: Props) {
    super(props);
    this.detailStore = new AppDetailStore(props.appId, props.version);
    when(() => !this.detailStore.loading, () => this.detailStore.sendJumbeEvent('view'));
  }

  public render() {
    if (this.detailStore.loading) {
      return (
        <LoadingIndicator />
      );
    } else if (this.detailStore.status === 404) {
      return (
        <div className={styles.error}>
          <Icon icon={IconNames.PATH_SEARCH} className={styles.icon} iconSize={40} />
          <div className={styles.text}>Just a 404, nothing to see here.</div>
          <Link to="/">Back to App Directory</Link>
        </div>
      );
    } else {
      return this.renderAppDetails();
    }
  }

  private renderBreadcrumbs() {
    if (this.props.hideBreadcrumbs) {
      return null;
    }
    return <Breadcrumbs />;
  }

  private renderAppDetails() {
    return (
      <div>
        {this.renderBreadcrumbs()}
        <div className={styles.main}>
          <AppInfoCard detailStore={this.detailStore} />
          <AppDetailTabs detailStore={this.detailStore} />
        </div>
      </div>
    );
  }
}

// Can't use the stock blueprint breadcrumbs because we need to use the react-router Link
const Breadcrumbs = () => {
  return useObserver(() => {
    return (
      <div className={styles.breadcrumbs}>
        <Icon icon={IconNames.CHEVRON_LEFT} className={styles.chevron} />
        <Link to="/">All Apps</Link>
      </div>
    );
  });
};
