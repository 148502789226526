import {Spinner} from '@blueprintjs/core';
import classNames from 'classnames';
import React from 'react';
import styles from './LoadingIndicator.module.sass';

interface Props {
  className?: string;
  size?: number;
}

export const LoadingIndicator = ({className, size = 50}: Props) => (
  <div className={classNames(className, styles.container)} >
    <Spinner size={size} />
  </div>
);
