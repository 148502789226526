import flatMap from 'lodash/flatMap';
import {computed, observable, reaction, toJS} from 'mobx';
import {ZipApi} from '../lib/ZipApi';
import {AppVersion} from '../types/AppVersion';
import {DirectoryStore, directoryStore} from './DirectoryStore';

export interface AppFilter {
  installed?: boolean;
  category?: string;
  search?: string;
}

export interface AppListItem extends AppVersion {
  installed?: boolean;
}

export class AppListStore {
  @observable public loaded = false;
  @observable public allApps: AppVersion[] = [];
  @observable public filter: AppFilter = {};

  constructor() {
    reaction(() => directoryStore.trackerId, () => {
      if (directoryStore.trackerId) {
        this.fetch();
      }
    });
  }

  public async fetch() {
    this.allApps = (await ZipApi.listApps(directoryStore.trackerId)).results;
    const permittedApps = directoryStore.permittedApps;
    if (permittedApps?.length) {
      this.allApps = this.allApps.sort((lhs, rhs) => {
        const lhsPermitted = permittedApps.includes(lhs.id.app_id);
        const rhsPermitted = permittedApps.includes(rhs.id.app_id);
        if (lhsPermitted && !rhsPermitted) {
          return -1;
        } else if (lhsPermitted && rhsPermitted) {
          return 0;
        } else if (!lhsPermitted && !rhsPermitted) {
          return 0;
        }

        return 1;
      });
    }

    this.loaded = true;
  }

  @computed
  public get apps(): AppListItem[] {
    return this.allApps.filter((app) => {
      let keep = true;
      if (this.filter.installed) {
        keep = keep && !!app.installed;
      }
      if (this.filter.search) {
        const text = app.metadata.display_name + ' ' + app.metadata.summary;
        keep = keep && this.filter.search.toLocaleLowerCase().split(/\W/g).every(
          (term) => text.toLowerCase().includes(term)
        );
      }
      if (this.filter.category) {
        keep = keep && app.metadata.categories.includes(this.filter.category);
      }
      return keep;
    });
  }

  @computed get categories() {
    const categorySet = new Set(flatMap(this.allApps, (app) => toJS(app.metadata.categories)));
    return Array.from(categorySet).sort((a, b) => a.toLocaleLowerCase().localeCompare(b));
  }

  public clearFilters() {
    this.filter = {};
  }

  public setFilter(filter: AppFilter) {
    this.filter = filter;
  }

  public hasFilters() {
    return !!(this.filter.category || this.filter.installed || this.filter.search);
  }
}

export const appListStore = new AppListStore();
