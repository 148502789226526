import {DateRange, DateRangePicker, IDateRangeShortcut} from '@blueprintjs/datetime';
import {Icon, Menu, MenuItem, Popover, PopoverInteractionKind, Position} from '@blueprintjs/core';
import React, {useCallback, useState} from 'react';
import {format, startOfDay, subDays, subHours, subMinutes} from 'date-fns';

import styles from './AppLogs.module.sass';

interface Props {
  dateRange: DateRange;
  handleDateChange: (range: DateRange | string) => void;
  isOpen: boolean;
  closePopover: () => void;
  openPopover: () => void;
}

const DATE_FORMAT = 'MMM dd, yyyy (HH:mm a)';
const now = new Date();
const fifteenMinsAgo = subMinutes(now, 15);
const thirtyMinsAgo = subMinutes(now, 30);
const hourAgo = subHours(now, 1);
const threeHoursAgo = subHours(now, 3);
const dayAgo = subDays(now, 1);
const sixHoursAgo = subHours(now, 6);
const twelveHoursAgo = subHours(now, 12);
const weekAgo = subDays(now, 7);

export const DATE_RANGE_SHORTCUTS = {
  PAST_15_MIN: 'Past 15 minutes',
  PAST_30_MIN: 'Past 30 minutes',
  PAST_HOUR: 'Past hour',
  PAST_3_HOURS: 'Past 3 hours',
  PAST_6_HOURS: 'Past 6 hours',
  PAST_12_HOURS: 'Past 12 hours',
  YESTERDAY: 'Yesterday',
  LAST_WEEK: 'Last week'
};

export const LogDateRangePicker = ({ dateRange, handleDateChange, isOpen, closePopover, openPopover }: Props) => {
  const [selectedShortcutIndex, setSelectedShortcutIndex] = useState(2);

  const shortcuts: IDateRangeShortcut[] = [
    {
      dateRange: [fifteenMinsAgo, now] as DateRange,
      includeTime: true,
      label: DATE_RANGE_SHORTCUTS.PAST_15_MIN
    },
    {
      dateRange: [thirtyMinsAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.PAST_30_MIN
    },
    {
      dateRange: [hourAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.PAST_HOUR
    },
    {
      dateRange: [threeHoursAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.PAST_3_HOURS
    },
    {
      dateRange: [sixHoursAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.PAST_6_HOURS
    },
    {
      dateRange: [twelveHoursAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.PAST_12_HOURS
    },
    {
      dateRange: [dayAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.YESTERDAY
    },
    {
      dateRange: [weekAgo, now] as DateRange,
      includeTime: true,
      label:  DATE_RANGE_SHORTCUTS.LAST_WEEK
    }
  ];
  const onShortcutChange = useCallback(
    (shortcut: IDateRangeShortcut, shortcutIndex: number) => () => {
      if (selectedShortcutIndex !== shortcutIndex) {
        setSelectedShortcutIndex(shortcutIndex);
        handleDateChange(shortcut.label);
        closePopover();
      }
    },
    [handleDateChange, selectedShortcutIndex, closePopover]
  );

  const preHandleDateChange = (range: DateRange) => {
    setSelectedShortcutIndex(-1);
    closePopover();
    handleDateChange(range);
  };

  const DateRangeSelected = () => (
    <>
      {dateRange[0] && <p className={styles['start-date']}>{format(dateRange[0], DATE_FORMAT)}</p>}
      {!dateRange[0] && dateRange[1] && <p>Then</p>}
      {(dateRange[0] || dateRange[1]) && <p>-</p>}
      {dateRange[0] && !dateRange[1] && <p>Now</p>}
      {dateRange[1] && <p>{format(dateRange[1], DATE_FORMAT)}</p>}
    </>
  );
  return (
    <Popover
      isOpen={isOpen}
      popoverClassName={styles['date-picker-popover']}
      interactionKind={PopoverInteractionKind.CLICK}
      position={Position.BOTTOM_LEFT}
      onClose={closePopover}
      content={
        <div className={styles['date-picker__container']}>
          <Menu>
            {shortcuts.map((shortcut, index) => (
              <MenuItem
                className={selectedShortcutIndex === index ? 'shortcut-selected' : ''}
                onClick={onShortcutChange(shortcut, index)}
                key={shortcut.label}
                text={shortcut.label}
              />
            ))}
          </Menu>
          <DateRangePicker
            timePrecision="minute"
            allowSingleDayRange
            value={dateRange}
            minDate={startOfDay(subDays(new Date(), 30))}
            maxDate={new Date()}
            onChange={preHandleDateChange}
            shortcuts={false}
          />
        </div>
      }
    >
      <div className="date-range-filter" onClick={openPopover}>
        <div className="date-value">
          {selectedShortcutIndex !== -1 ? <>{shortcuts[selectedShortcutIndex].label}</> : <DateRangeSelected />}
        </div>
        <Icon icon="chevron-down" className="calendar-icon" />
      </div>
    </Popover>
  );
};
