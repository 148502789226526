import { AppDetailStore } from '../../../stores/AppDetailStore';
import { AppLogEntry } from '../../../types/AppLogEntry';
import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingIndicator } from '../../../components/LoadingIndicator';
import React from 'react';
import styles from './AppLogContent.module.sass';
import { useObserver } from 'mobx-react-lite';

interface Props {
  detailStore: AppDetailStore;
}

export const AppLogContent = ({ detailStore }: Props) => {
  const fetchMoreData = () => {
    detailStore.fetchMore();
  };

  return useObserver(() => {
    if (!detailStore.logLoaded) {
      return <LoadingIndicator />;
    }
    const appLogs = detailStore.appLogs;
    if (appLogs.length === 0) {
      return (
        <div className={styles.emptyAppLog}>
          <Icon icon={IconNames.SEARCH} iconSize={64} />
          <p className={styles.info}>No log found</p>
        </div>
      );
    }
    const renderLogLevelElem = (log: AppLogEntry) => {
      return <span className={`log-${log.level ? log.level.toLowerCase() : 'info'}`}>{log.level}</span>;
    };

    return (
      <div className={styles['log-messages']} id="log-messages">
        <InfiniteScroll
          dataLength={appLogs.length}
          next={fetchMoreData}
          // style={{ display: 'flex', flexDirection: 'column-reverse' }} //To put endMessage and loader to the top.
          // inverse={true}
          hasMore={detailStore.hasMoreLogs}
          loader={<LoadingIndicator size={25} className={styles['loading-spinner']} />}
          scrollableTarget="log-messages"
        >
          <div className={styles['log-messages-items']}>
            {appLogs.map((log, idx) => (
              <React.Fragment key={`log-line-${log.time}-${idx}`}>
                <div className="log-line log-index-col">{idx + 1}</div>
                <div className="log-line log-content-col">
                  <span className="log-datetime">{log.time}</span>
                  {renderLogLevelElem(log)}
                  <span>{log.message}</span>
                </div>
              </React.Fragment>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    );
  });
};
