import {History} from 'history';
import * as queryString from 'query-string';
import {ParsedQuery} from 'query-string';

export function addToQuery(history: History, partialQuery: ParsedQuery) {
  const query = queryString.parse(history.location.search);
  setQuery(history, Object.assign(query, partialQuery));
}

export function setQuery(history: History, query: ParsedQuery) {
  const location = Object.assign({}, history.location);
  location.search = queryString.stringify(query);
  history.replace(location);
}
